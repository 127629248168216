<template>
  <div class="singleHifi">
    <div class="banner">
      <div class="bannerBox">
        <div class="name">Hi-Fi蓝牙功放</div>
      </div>
    </div>
    <!-- 相关参数 -->
    <div class="paramBox">
      <div class="param">
        <div class="title">Hi-Fi蓝牙功放</div>
        <div class="paramTit">相关参数</div>
        <div class="pas">
          <div class="pasLeft">
            <div class="li">
              <div class="pasName">产品型号:</div>
              <div class="pasCont">LS-BT-AMP01A</div>
            </div>
            <div class="li">
              <div class="pasName">额定输入:</div>
              <div class="pasCont">AC200V~250V/50Hz</div>
            </div>
            <div class="li">
              <div class="pasName">蓝牙版本:</div>
              <div class="pasCont">蓝牙4.2</div>
            </div>
            <div class="li">
              <div class="pasName">功放额定输出:</div>
              <div class="pasCont">2×50W（8Ω）</div>
            </div>
            <div class="li">
              <div class="pasName">功放频率响应:</div>
              <div class="pasCont">22Hz~20KHz±3dB</div>
            </div>
          </div>
          <div class="pasRight">
            <div class="li">
              <div class="pasName">功放失真度:</div>
              <div class="pasCont">0.05%@1KHz,1W</div>
            </div>
            <div class="li">
              <div class="pasName">变压器功率:</div>
              <div class="pasCont">环牛200W</div>
            </div>
            <div class="li">
              <div class="pasName">工作湿度:</div>
              <div class="pasCont">0%-95%RH，无冷凝</div>
            </div>
            <div class="li">
              <div class="pasName">工作频段（可选）:</div>
              <div class="pasCont">433.0MHz~453.0MHz</div>
            </div>

            <div class="li">
              <div class="pasName">无线发射功率（可选）:</div>
              <div class="pasCont">＜10dBm</div>
            </div>
          </div>
        </div>
        <div class="text">
          <div
            class="tx"
          >Hi- Fi蓝牙功放是一个大功率高品质的客房音乐播放功放，能够通过蓝牙连接手机或主机，接收音源发出的数字音频信号，同时驱动大功率高品质扬声器还原出清晰纯净的音乐。可配合主机实现音乐的音量、切换曲目等功能。</div>
        </div>
        <div class="star">* 以上所有数据来自BOT实验室，依据测量方式不同,实际结果可能略有差异。</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
// banner
.banner {
  width: 100%;
  height: 6rem;
  background: url("../../../assets/product/singleWifi.png");
  background-size: 100% 100%;
  .bannerBox {
    width: 12rem;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .name {
      display: block;
      text-align: left;
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: bold;
      color: #333333;
    }
  }
}
// 相关参数
.paramBox {
  width: 100%;
  background-color: #fff;
  .param {
    width: 12rem;
    margin: 0 auto;
    padding-bottom: 1.2rem;
    text-align: left;
    .title {
      padding: 0.39rem 0px;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: 400;
      color: #ff9000;
      border-bottom: 1px solid #333;
    }
    .paramTit {
      margin-top: 0.38rem;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
      color: #333;
    }
    .pas {
      margin-top: 0.79rem;
      display: flex;
      text-align: left;
      justify-content: space-between;
      div {
        display: flex;
        flex-direction: column;
        .li {
          display: flex;
          flex-direction: row;
          margin-bottom: 0.38rem;
          font-size: 0.24rem;
          line-height: 0.24rem;
          font-weight: 400;
          color: #333333;
          &:nth-of-type(1),
          &:nth-of-type(2),
          &:nth-of-type(3) {
            .pasName {
              margin-right: 1.28rem;
            }
          }
          &:last-of-type {
            margin-bottom: 0px;
          }
          .pasName {
            margin-right: 0.79rem;
          }
        }
      }
      .pasRight {
        .li {
          .pasName {
            margin-right: 0.96rem;
          }
          &:nth-of-type(1),
          &:nth-of-type(2) {
            .pasName {
              margin-right: 1.68rem;
            }
          }
          &:nth-of-type(3) {
            .pasName {
              margin-right:1.92rem;
            }
          }
          &:last-of-type {
            .pasName {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
    .text {
      margin-top: 0.43rem;
      border-top: 1px solid #333;
      padding-top: 0.4rem;
      text-align: justify;
      font-size: 0.24rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
    }
    .star {
      margin-top: 0.8rem;
      text-align: left;
      font-size: 0.1rem;
      line-height: 0.1rem;
      font-weight: 300;
      color: #333333;
    }
  }
}
</style>
